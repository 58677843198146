// Generated by ReScript, PLEASE EDIT WITH CARE

import * as VideoAdBindJs from "./VideoAd.bind.js";

var make = VideoAdBindJs.NpVideo;

var Np = {
  make: make
};

export {
  Np ,
  
}
/* make Not a pure module */
