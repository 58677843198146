// import VenAdHor from "../ads/VenAdHor";
import { make as AdHor } from "../ads/AdHor.bs";

type Props = {
  children: JSX.Element | Array<JSX.Element>;
  adKey?: string;
};

export default function AdLayout({ children, adKey }: Props) {
  return (
    <div className="flex flex-col items-center flex-shrink-1">
      {/* <VenAdHor key={adKey} /> */}
      <AdHor key={adKey} />
      <div
        className="flex flex-col items-center mt-4 md:mt-6 w-full"
        id="content-container"
      >
        {children}
      </div>
    </div>
  );
}
