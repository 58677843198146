import { useEffect, useLayoutEffect, useRef } from "react";
import { isProd } from "common/src/Env.bind";

export function NpVideo({ screenSize }) {
  const el = useRef(null);
  const id =
    screenSize < 2
      ? "np-video"
      : screenSize < 4
      ? "np-video-md"
      : "np-video-sm";

  useLayoutEffect(() => {
    el.current.id = id;
  }, [id]);

  useEffect(() => {
    if (window.afm) {
      return;
    }
    if (window.initAdIds.includes(id)) {
      window.initAdIds = window.initAdIds.filter((i) => i !== id);
      return;
    }

    window["nitroAds"]?.createAd(id, {
      demo: !isProd,
      format: "video-nc",
      video: {
        // interval: 30,
        hidePlaylist: true,
      },
    });
  }, [id]);

  return (
    <div
      ref={(ref) => (el.current = ref)}
      key={id}
      id={id}
      dangerouslySetInnerHTML={{ __html: "" }}
      suppressHydrationWarning
    />
  );
}
